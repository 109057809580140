.form {
    padding: 20px 20px 0px 20px;
}
.btn {
    width: 100%;
}
.btn:disabled {
    color: #313131;
    background-color: #9b9b9b;
    border-color: #9b9b9b;
}